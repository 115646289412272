import { PageProps } from "gatsby"
import React from "react"
import styled from "styled-components"
import ContentContainer from "../components/content-container"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/storyblok/header"
import RichText, { textRenderer } from "../components/storyblok/richText"
import Typography from "../components/typography"
import useStoryblok from "../lib/storyblok"

const StyledContentDownload = styled.a`
  display: block;
  text-decoration: none;
  color: var(--blue-500);
  font-weight: 500;

  &:hover {
    color: var(--blue-600);
    text-decoration: underline;
  }
`

const News: React.FC<PageProps<object, any>> = ({ pageContext, location }) => {
  const story = useStoryblok(pageContext.storyblokEntry, location)

  return (
    <Layout>
      <Seo title={story.content.title} />
      {pageContext?.noHeader ? null : <Header blok={{ title: "News" }} />}
      <NewsTemplate story={story} />
    </Layout>
  )
}

export const NewsTemplate: React.FC<{ story: any; pt?: number }> = ({
  story,
  pt,
}) => (
  <ContentContainer pt={pt}>
    <Typography variant="h3">{story.content.title}</Typography>
    <Typography variant="h4" color="gray-2" mb={3}>
      {new Date(story.content.date).toLocaleDateString("en-UK", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })}
    </Typography>
    {textRenderer(story.content.long_text, { openLinkInNewTab: true })}
    {story.content.file ? (
      <StyledContentDownload
        href={story.content.file.filename}
        target="_blank"
        rel="noopener noreferrer"
      >
        {story.content.file_title}
      </StyledContentDownload>
    ) : null}
  </ContentContainer>
)

export default News
