import { graphql as gql, PageProps } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useStoryblok from "../lib/storyblok"
import useStoryblokComponent from "../lib/useStoryblokComponent"
import { NewsTemplate } from "../templates/news"

const News: React.FC<PageProps<any>> = ({ data, location }) => {
  const story = useStoryblok(data.storyblokEntry, location)

  const StoryblokComponents = useStoryblokComponent(story)

  return (
    <Layout>
      <Seo title="News" />
      {StoryblokComponents}
      {data.allStoryblokEntry.nodes.map((node, index) => (
        <>
          <NewsTemplate
            key={node.content.full_slug}
            pt={index !== 0 ? 0 : undefined}
            story={{ ...node, content: JSON.parse(node.content) }}
          />
        </>
      ))}
    </Layout>
  )
}

export default News

export const query = gql`
  query NewsPageQuery {
    allStoryblokEntry(
      filter: { full_slug: { glob: "news/*" } }
      sort: { fields: field_date_string, order: DESC }
    ) {
      nodes {
        content
        name
        full_slug
      }
    }
    storyblokEntry(slug: { eq: "news" }) {
      full_slug
      content
    }
  }
`
